<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">

    <label slot="label">
      <span
        v-for="type in types"
        :key="type.value"
        class="pointer"
        :class="{'text-muted': type.value !== selectedGroup}"
        @click="setGroup(type.value)">
        {{type.text}}&nbsp;
      </span>
    </label>

    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  DP_DEVICES,
  DP_DEVICE_TYPES
} from './graph'

export default {
  name: 'DetailDeviceInput',
  props: {
    templateType: {
      type: String,
      required: true
    },
    procedureType: {
      type: String,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    oneWay: {
      type: Boolean,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    tag: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      display: null,
      options: [],
      types: [],
      search: '',
      selectedGroup: ''
    }
  },
  methods: {
    fetchTypes () {
      this.$apollo.query({
        query: DP_DEVICE_TYPES(this.templateType),
        variables: {
          procedureType: this.procedureType,
          tag: this.tag
        }
      })
      .then(res => {
        this.types = res.data.types
      })
    },
    setGroup (value) {
      this.selectedGroup = value
    },
    getDisplay (option) {
      return `${option.code} (${option.name})`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.$emit('change', option)
      this.emitInput(this.optionByKey(option))
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: DP_DEVICES(this.templateType),
        variables: {
          procedureType: this.procedureType,
          tag: this.tag,
          q: {
            limit: 10,
            params: {
              search: this.search,
              type: this.selectedGroup || undefined
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.options = res.data.items
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }, 300),
    fetchData (id) {
      this.$apollo.query({
        query: DP_DEVICES(this.templateType),
        variables: {
          procedureType: this.procedureType,
          tag: this.tag,
          q: {
            limit: 1,
            params: {
              id
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const inventories = res.data.items
        this.options = inventories
        this.setDisplay(inventories[0])
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (value) {
        if (!this.oneWay && value) {
          this.fetchData(value)
        } else if (!value) {
          this.display = null
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    this.fetchTypes()
  }
}
</script>

<style lang="css">
</style>
