<template>
  <div>
    <DetailMasterGroup
      class="mb-3"
      v-for="(child, idx) in formData.master.children"
      :idx="idx"
      :key="child.id"
      :item="child"
      :procedureType="procedureType"
      :templateType="templateType"
      :parentId="parentId"
      :isEdit="isEdit"
      :formData="formData"
      @updated="emitUpdated">
    </DetailMasterGroup>
  </div>
</template>

<script>
import DetailMasterGroup from './DetailMasterGroup.vue'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailMasterGroup
  }
}
</script>

<style lang="css" scoped>
</style>
