<template>
  <div class="card">
    <div class="card-body">
      <span class="card-text">
        <div class="">
          <span class="h6">{{item.code}} ({{item.name}})</span>

          <span
            v-if="isEdit && !isExist(item.code)"
            class="float-right px-2 pointer text-success"
            @click="createData">
            <fa icon="plus"></fa>
          </span>

          <span
            v-else-if="isEdit"
            class="float-right px-2 pointer text-success"
            @dblclick="createItemDataAll">
            เพิ่มทั้งหมด
          </span>
        </div>

        <DetailMasterGroupItem
          class="mt-3"
          v-for="(card, cIdx) in item.children"
          :idx="cIdx"
          :key="card.id"
          :item="card"
          :parentId="isExist(item.code)"
          :procedureType="procedureType"
          :templateType="templateType"
          :isEdit="isEdit"
          :isItemExist="isItemExist(item.code, card)"
          :formData="formData"
          @created="createItemData">
        </DetailMasterGroupItem>
      </span>
    </div>
  </div>
</template>

<script>
import DetailMasterGroupItem from './DetailMasterGroupItem'

import {
  CREATE_GROUP,
  CREATE_GROUP_ITEM,
} from './graph'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    formData: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
    }
  },
  methods: {
    isExist (code) {
      const parent = this.formData.children.find(v => v.code === code)
      if (!parent) return null
      return parent.id
    },
    isItemExist (code, item) {
      return this.formData.children.findIndex(group => {
        const g = group.code === code
        if (!g) return false
        const x = group.children.findIndex(v => v.code === item.code)
        return x !== -1
      }) !== -1
    },
    serializeInput () {
      return {
        code: this.item.code,
        name: this.item.name
      }
    },
    createData () {
      this.$apollo.mutate({
        mutation: CREATE_GROUP(this.templateType),
        variables: {
          procedureType: this.procedureType,
          parentId: this.parentId,
          input: this.serializeInput()
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    serializeItemInput (item) {
      const choices = item.choices ? item.choices.map(v => ({text: v.text, value: v.value})) : null
      return {
        code: item.code,
        name: item.name,
        description: item.description,
        formType: item.formType,
        choices,
        preValue: item.preValue,
        minValue: item.minValue,
        maxValue: item.maxValue,
        cron: item.cron,
        inventoryId: item.inventoryId,
        deviceId: item.deviceId,
      }
    },
    createItemData ({item, parentId}) {
      return this.$apollo.mutate({
        mutation: CREATE_GROUP_ITEM(this.templateType),
        variables: {
          procedureType: this.procedureType,
          parentId,
          input: this.serializeItemInput(item)
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    createItemDataAll () {
      const items = this.item.children.filter(child => !this.isItemExist(this.item.code, child))
      const parentId = this.isExist(this.item.code)

      const tasks = items.map(item => {
        return this.createItemData({item, parentId})
      })

      Promise.all(tasks)
      .then(() => this.emitUpdated())
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailMasterGroupItem
  }
}
</script>

<style lang="css" scoped>
</style>
