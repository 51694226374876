import gql from 'graphql-tag'


const detailResponse = `
  id type code name order parentId
  description formType
  preValue minValue maxValue
  choices {text value}
  cron
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
  deviceId device {
    id type code name
  }
`

export const CREATE_GROUP_ITEM = (templateType) => gql`mutation CREATE_GROUP_ITEM ($procedureType: String!, $parentId: Int!, $input: ${templateType}GroupItemInput!) {
  createGroupItem: create${templateType}GroupItem (procedureType: $procedureType, parentId: $parentId, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP_ITEM = (templateType) => gql`mutation UPDATE_GROUP_ITEM ($procedureType: String!, $groupItemId: Int!, $input: ${templateType}GroupItemInput!) {
  updateGroupItem: update${templateType}GroupItem (procedureType: $procedureType, groupItemId: $groupItemId, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP_ITEM = (templateType) => gql`mutation DESTROY_GROUP_ITEM ($procedureType: String!, $groupItemId: Int!) {
  destroyGroupItem: destroy${templateType}GroupItem (procedureType: $procedureType, groupItemId: $groupItemId) {id}
}`

export const MOVE_GROUP_ITEM = (templateType) => gql`mutation MOVE_GROUP_ITEM ($procedureType: String!, $groupItemId: Int!, $step: Int!) {
  moveGroupItem: move${templateType}GroupItem (procedureType: $procedureType, groupItemId: $groupItemId, step: $step) {id}
}`
