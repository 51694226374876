import gql from 'graphql-tag'


const detailResponse = `
  id type code name isActive
  categories {id name}
  children {
    id type code name order parentId
    children {
      id type code name order parentId
      description formType
      preValue minValue maxValue
      choices {text value}
      cron
      inventoryId inventory {
        id type code name
        unitId unit {id name}
      }
      deviceId device {
        id type code name
      }
    }
  }
  masterId master {
    id type code name
    children {
      id type code name order parentId
      children {
        id type code name order parentId
        description formType
        preValue minValue maxValue
        choices {text value}
        cron
        inventoryId inventory {
          id type code name
          unitId unit {id name}
        }
        deviceId device {
          id type code name
        }
      }
    }
  }
`

export const LIST_PROCEDURE_QUALITY = (templateType) => gql`query LIST_PROCEDURE_QUALITY ($procedureType: String!, $q: FilterInput) {
  procedureQualities: list${templateType} (procedureType: $procedureType, q: $q) {
    id type code name isActive
  }
}`

export const DETAIL_PROCEDURE_QUALITY = (templateType) => gql`query DETAIL_PROCEDURE_QUALITY ($procedureType: String!, $procedureQualityId: Int!) {
  procedureQuality: detail${templateType} (procedureType: $procedureType, procedureQualityId: $procedureQualityId) {${detailResponse}}
}`

export const CREATE_PROCEDURE_QUALITY = (templateType) => gql`mutation CREATE_PROCEDURE_QUALITY ($procedureType: String!, $input: ${templateType}Input!) {
  createProcedureQuality: create${templateType} (procedureType: $procedureType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROCEDURE_QUALITY = (templateType) => gql`mutation UPDATE_PROCEDURE_QUALITY ($procedureType: String!, $procedureQualityId: Int!, $input: ${templateType}Input!) {
  updateProcedureQuality: update${templateType} (procedureType: $procedureType, procedureQualityId: $procedureQualityId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROCEDURE_QUALITY = (templateType) => gql`mutation DESTROY_PROCEDURE_QUALITY ($procedureType: String!, $procedureQualityId: Int!) {
  destroyProcedureQuality: destroy${templateType} (procedureType: $procedureType, procedureQualityId: $procedureQualityId) {id}
}`
