import gql from 'graphql-tag'


const detailResponse = `
  id type code name order parentId
  children {
    id type code name order parentId
    description formType
    preValue minValue maxValue
    choices {text value}
    cron
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
    deviceId device {
      id type code name
    }
  }
`

export const CREATE_GROUP = (templateType) => gql`mutation CREATE_GROUP ($procedureType: String!, $parentId: Int!, $input: ${templateType}GroupInput!) {
  createGroup: create${templateType}Group (procedureType: $procedureType, parentId: $parentId, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP = (templateType) => gql`mutation UPDATE_GROUP ($procedureType: String!, $groupId: Int!, $input: ${templateType}GroupInput!) {
  updateGroup: update${templateType}Group (procedureType: $procedureType, groupId: $groupId, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP = (templateType) => gql`mutation DESTROY_GROUP ($procedureType: String!, $groupId: Int!) {
  destroyGroup: destroy${templateType}Group (procedureType: $procedureType, groupId: $groupId) {id}
}`

export const MOVE_GROUP = (templateType) => gql`mutation MOVE_GROUP ($procedureType: String!, $groupId: Int!, $step: Int!) {
  moveGroup: move${templateType}Group (procedureType: $procedureType, groupId: $groupId, step: $step) {id}
}`
