<template>
  <div class="form-row" v-if="categories.length > 0">
    <div class="col-12">
      <sgv-input-check-array
        label="หมวดหมู่"
        :disabled="disabled"
        :options="categories"
        inline
        :value="value"
        select="id"
        @input="emitInput">
        <template slot-scope="option">
          <span :class="{'text-success': value.find(v => v.id === option.id)}">
            {{option.name}}
          </span>
        </template>
      </sgv-input-check-array>
    </div>
  </div>
</template>

<script>
import { LIST_CATEGORY } from './graph/category'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      categories: []
    }
  },
  apollo: {
    categories: {
      query () {
        return LIST_CATEGORY(this.templateType)
      },
      variables () {
        return {
          procedureType: this.procedureType,
          q: {
            order: 'code'
          }
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="css">
</style>
