<template>
  <div class="">
    <div class="">
      <span class="">
        <span
          v-if="isEdit && !isItemExist && parentId"
          class="float-right px-2 pointer text-success"
          @click="createData">
          <fa icon="plus"></fa>
        </span>

        <div class="">
          <span>
            {{idx+1}}. {{item.code}} ({{item.name}})
          </span>

          <div
            v-if="item.description"
            class="text-primary pre-line pl-3">
            <small>{{item.description}}</small>
          </div>
          <div class="row mt-1">
            <dd class="col-4 col-sm-3 text-right text-warning">ค่า:</dd>
            <dd class="col-8 col-sm-9">
              <span class="text-danger pr-1">{{item.minValue}}</span>
              <fa icon="less-than-equal"></fa>
              {{item.preValue}}
              <fa icon="less-than-equal"></fa>
              <span class="text-success pl-1">{{item.maxValue}}</span>
            </dd>

            <template v-if="item.device">
              <dd class="col-4 col-sm-3 text-right text-primary">Remote:</dd>
              <dd class="col-8 col-sm-9">
                {{item.device ? `${item.device.code} (${item.device.name})` : '-'}}
              </dd>
            </template>

            <template v-if="item.inventory">
              <dd class="col-4 col-sm-3 text-right text-warning">เครื่องมือ:</dd>
              <dd class="col-8 col-sm-9">
                {{item.inventory ? `${item.inventory.code} (${item.inventory.name})` : '-'}}
              </dd>
            </template>

            <template v-if="item.cron">
              <dd class="col-4 col-sm-3 text-right">ความถี่ (cron):</dd>
              <dd class="col-8 col-sm-9">{{item.cron || '-'}}</dd>
            </template>

            <template v-if="item.formType === 'choice' && item.choices && item.choices.length > 0">
              <dd class="col-4 col-sm-3 text-right text-info">ตัวเลือก:</dd>
              <dd class="col-8 col-sm-9">
                <ul class="list-unstyled mb-0">
                  <li v-for="choice in item.choices" :key="choice.text">
                    {{choice.text}} ({{choice.value}})
                  </li>
                </ul>
              </dd>
            </template>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    isItemExist: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      formTypes: [
        {text: 'ตัวเลือก', value: 'choice'},
        {text: 'ระหว่าง', value: 'between'},
      ]
    }
  },
  methods: {
    createData () {
      if (!this.parentId) return

      this.$emit('created', {item: this.item, parentId: this.parentId})
    },
  }
}
</script>

<style lang="css" scoped>
</style>
